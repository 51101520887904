@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Thin.otf) format('opentype');
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-ExtraLight.otf) format('opentype');
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Light.otf) format('opentype');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Book.otf) format('opentype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Regular.otf) format('opentype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-SemiBold.otf) format('opentype');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Bold.otf) format('opentype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Heavy.otf) format('opentype');
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: 'Mont';
	src: url(./fonts/Mont-Black.otf) format('opentype');
	font-style: normal;
	font-weight: 900;
}

html {
	scroll-behavior: smooth;
}

.App {
	/* text-align: center; */
	font-family: Mont, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	background-color: #050f04;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.menu-option {
	opacity: 0.6;
}

.menu-option:hover {
	opacity: 0.8;
}

.active-menu-option {
	text-decoration: underline !important;
	opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.active-tab {
	opacity: 1;
	background-color: #050f04 !important;
}

.active-tab:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border: 1px solid #223524;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	border-bottom: 0;
	box-sizing: border-box;
}

.inactive-tab {
	color: rgba(255, 255, 255, 0.6) !important;
	border: 0 !important;
}

.inactive-tab:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border: 1px solid #223524;
	border-top: 0;
}

.core-inactive:before {
	border-left: 0;
	border-bottom-right-radius: 12px;
}

.advanced-inactive:before {
	border-bottom-left-radius: 12px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
